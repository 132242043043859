<template>
  <!-- eslint-disable -->

  <div class="flex-lg-row-fluid ms-lg-10">
    <div class="card mb-5 mb-xl-10">
      <div class="card-header card-header-stretch">
        <h3 class="card-title fw-bolder text-gray-800 fs-2">Aplikacje</h3>
        <router-link :to="'/admin/organisations/'+id+'/apps/add'" v-if="profile.is_admin" class="btn btn-primary align-self-center btn-sm">Dodaj aplikację</router-link>
        <router-link :to="'/dashboard/apps/add'" v-else class="btn btn-primary align-self-center btn-sm">Dodaj aplikację</router-link>
      </div>
      <div class="card-body py-0">
        <div class="table-responsive">
          <table class="table align-middle table-row-bordered table-row-dashed gy-5" id="kt_table_widget_1">
            <tbody>
              <tr class="text-start text-gray-400 fw-boldest fs-7 text-uppercase">
                <th class="min-w-125px px-0">Nazwa</th>
                <th class="min-w-100px text-center">Status</th>
                <th class="min-w-125px">Uprawnienia</th>
              </tr>
              <tr v-for="app in apps">
                <td class="p-0 align-top pt-5 pb-5">
                  <div class="d-flex align-items-center">
                    <div class="">
                      <router-link v-if="profile.is_admin" :to="{ name: 'AdminAppsDetails', params: { id: organisation.uuid, appid: app.id }}" class="text-gray-800 fw-boldest fs-6 text-hover-primary mb-1">
                        {{ app.name }}
                      </router-link>
                      <router-link v-else :to="{ name: 'AppsDetails', params: { id: organisation.uuid, appid: app.id }}" class="text-gray-800 fw-boldest fs-6 text-hover-primary mb-1">
                        {{ app.name }}
                      </router-link>
                      <span class="text-gray-400 fw-bold d-block">
                        {{ app.id }}
                      </span>
                    </div>
                  </div>
                </td>
                <td class="align-top pt-5 pb-5 text-center">
                  <span v-if="app.status == 'OK'" class="badge badge-success badge-copy">OK</span>
                  <span v-else-if="app.status == 'Nieaktywna'" class="badge badge-secondary badge-copy">{{ app.status }}</span>
                  <span v-else class="badge badge-warning text-dark badge-copy">{{ app.status }}</span>
                </td>
                <td class="align-top pt-5 pb-5">
                  <div class="d-flex align-items-center">
                    <ul class="scopes-list">
                      <li v-for="scope in app.scopes">{{ scope.description }}</li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Apps',
  inject: ['$profile', '$organisation'],
  data() {
    return {
      apps: [],
      adminMode: false,
      logged: false
    }
  },
  /*
  beforeRouteUpdate(to, from, next) {
    this.reload();
    next();
  },*/
  created () {
      this.reload();
      this.timer = setInterval(this.reload, 60000);
  },
  mounted () {
    this.$bus.on('updateAdminMode', (value) => { // here you need to use the arrow function
     this.adminMode = value;
    });
    this.adminMode = this.$root.$refs.topbar.adminMode;
  },
  methods: {
    updateApps: function() {
      var self = this;
      var url = '';
      if (this.profile.is_admin)
      {
        url = 'organisations/' + this.$route.params.id + '/apps';
      }
      else
      {
        url = '/me/organisation/apps';
      }
      this.$root.$refs.api.get(url).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.apps = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    reload: function() {
      this.updateApps();
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
    this.$bus.off('updateAdminMode');
  },
  computed: {
    organisation() {
      return this.$organisation();
    },
    profile() {
      return this.$profile();
    }
  }
};
</script>

<style>
ul.scopes-list {
  list-style-type: none;
  padding: 0;
}
ul.scopes-list li {
  padding: 0;
}
</style>
